import React, { useEffect, useState } from "react"
import labels from "./const"
import useLabels from "../../utils/useLabels"
import { Col, Row, Container, Button } from "reactstrap"

import * as s from "./style.module.css"
import { StaticImage } from "gatsby-plugin-image"

function Hero({ lang, type }) {
  const [content, setContent] = useState({})
  const t = useLabels(labels, lang)

  useEffect(() => {
    switch (true) {
      case type === "heroHome":
        setContent(t.heroHome)
        break
      case type === "hero2022":
        setContent(t.hero2022)
        break
      case type === "hero2023":
        setContent(t.hero2023)
        break
      case type === "hero2024":
        setContent(t.hero2024)
        break 
      default:
        break
    }
  }, [t])

  return (
    <div className={s.bg}>
      <Row>
        <Col lg="6" md="8" xs="12" className="position-relative">
          <img
            alt=""
            className={s.mapTop}
            src={require("./images/dot-map-top.svg").default}
          />
          <div className={s.ellipse} />
          <img
            alt=""
            className={s.mapBottom}
            src={require("./images/dot-map-bottom.svg").default}
          />
        </Col>
        <Col md="4" lg="6">
          <StaticImage
            alt=""
            className={s.heroText}
            loading="eager"
            src="./images/heroText.png"
          />
        </Col>
      </Row>
      <div className={`position-absolute w-100 h-100 ${s.top}`}>
        <Container className={`${s.container} h-100`}>
          <Row className="h-100">
            <Col lg="6" md="8" className="h-100">
              <div class="position-relative d-flex align-items-center h-100">
                <div>
                  <StaticImage
                    alt=""
                    style={{display: 'none'}}
                    className={s.mobileImage}
                    src="./images/heroBG.jpg"
                  />
                  <div
                    className={s.subtitle}
                    dangerouslySetInnerHTML={{ __html: content.subtitle }}
                  />
                  <div
                    className={s.date}
                    dangerouslySetInnerHTML={{
                      __html: content.date,
                    }}
                  />
                  <div
                    className={s.description}
                    dangerouslySetInnerHTML={{
                      __html: content.description,
                    }}
                  />

                  <a href="https://evenea.pl/pl/wydarzenie/hack4change2025" target="_blank">
                    <Button
                      color="primary"
                      dangerouslySetInnerHTML={{
                        __html: content.button,
                      }}
                    />
                  </a>
                </div>
              </div>
            </Col>
            <Col md="4" lg="6"></Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Hero
